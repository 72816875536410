import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { PubSub } from 'lib/event/PubSub';
import { AsyncErrorHandler, ComponentLoadingFader } from 'lib/ui';
import { MooringAnchorLine, MooringComponentType, UpdateMooringAnchorLineDto } from 'models/mooring';
import { ToastService } from 'services';
import { MooringService } from 'services/mooring';

@autoinject
export class AnchorLinesContainer {
  @bindable
  protected mooringId: number;

  protected anchorLines: MooringAnchorLine[] = [];

  constructor(
    protected element: Element,
    private mooringService: MooringService,
    private toaster: ToastService,
    private t: I18N,
    private pubsub: PubSub
  ) {
    pubsub.sub('entity:updated', async () => {
      void this.getAnchorLines();
    });

    pubsub.sub('list-entity:updated', async () => {
      void this.getAnchorLines();
    });
  }

  @AsyncErrorHandler
  @ComponentLoadingFader
  async attached() {
    if (!this.mooringId) {
      throw new Error('mooringId is required => mooring-id.bind="<id>"');
    }

    await this.getAnchorLines();
  }

  @AsyncErrorHandler
  protected async getAnchorLines() {
    const { data } = await this.mooringService.getAnchorLines(this.mooringId);
    const anchorLines = data;
    if (anchorLines.length) {
      for (const line of anchorLines) {
        line.Components?.sort((a, b) => a.SortIndex - b.SortIndex);
      }
      setTimeout(() => {
        this.anchorLines = anchorLines;
      }, 10);
    }
  }

  @AsyncErrorHandler
  protected async onSaveAnchorLines({ entries, canNavigateOnSave }: { entries: MooringAnchorLine[]; canNavigateOnSave: boolean }) {
    const dto = entries.map(
      (line) =>
        ({
          Id: line.Id,
          Slope: line.Slope,
          BuoyId: line.BuoyId,
          BuoyNo: line.BuoyNo,
          MoorlogNo: line.MoorlogNo,
          DimForce: line.DimForce,
          AnalysisNo: line.AnalysisNo,
          IsAccident: line.IsAccident,
          TotalLength: line.TotalLength,
          LengthOfRope: line.LengthOfRope,
          StopPositionLat: line.StopPositionLat,
          StartPositionLat: line.StartPositionLat,
          StopPositionLong: line.StopPositionLong,
          MinMblAnchoring: line.MinMblAnchoring,
          StartPositionLong: line.StartPositionLong,
          LengthOfBottomRope: line.LengthOfBottomRope,
          MinMblRopesStraps: line.MinMblRopesStraps,
          BottomAnchoringUplift: line.BottomAnchoringUplift,
          DepthAtAnchoringPoint: line.DepthAtAnchoringPoint,
          HoldingPowerAnchoring: line.HoldingPowerAnchoring,
          MinMblChainShackleConnectionPlates: line.MinMblChainShackleConnectionPlates,
          Components: line.Components,
        }) satisfies UpdateMooringAnchorLineDto
    );

    await this.mooringService.updateAnchorLines(this.mooringId, dto);
    this.toaster.showUpdated();
    this.pubsub.publish('list-entity:updated', { name: MooringComponentType.anchorLine });
    if (canNavigateOnSave) {
      this.pubsub.publish('tabs:change-guard-ok', null);
    } else {
      await this.getAnchorLines();
    }
  }

  protected unbind() {
    this.pubsub.unsub();
  }

  protected detached() {
    this.pubsub.unsub();
  }
}
