import { autoinject, bindable } from 'aurelia-framework';
import { ListFieldTyped } from 'models/ListSettings';
import { CommentForEntity, MooringAnchorLine, MooringComponent, MooringComponentType } from 'models/mooring';
import { MooringComponentSearchHandler, MooringService } from 'services/mooring';
import { MooringArticleService, ToastService } from 'services';
import { toKg, toM, toChecked } from 'lib/ui/ValueDisplayFormatters';
import { DialogService } from 'aurelia-dialog';
import { TableEntryManager } from 'services/ui-helpers';
import { MooringTableBase } from '../table-base';
import { PubSub } from 'lib/event/PubSub';
import { I18N } from 'aurelia-i18n';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { AsyncErrorHandler } from 'lib/ui';
import { TableManager } from '../TableManager';

@autoinject
export class AnchorLinesTable extends MooringTableBase<MooringAnchorLine> {
  protected table = new TableManager('anchor-lines-table', tableColumns);

  @bindable
  protected onSave: (args: { entries: MooringAnchorLine[]; canNavigateOnSave: boolean }) => void;

  constructor(
    protected t: I18N,
    protected toast: ToastService,
    dialogService: DialogService,
    mooringArticleService: MooringArticleService,
    // Used in HTML for article search
    mooringComponentSearchHandler: MooringComponentSearchHandler,
    tableEntryManager: TableEntryManager,
    pubsub: PubSub,
    confirmation: ConfirmDialog,
    mooringService: MooringService
  ) {
    super(
      'anchor-line',
      dialogService,
      mooringComponentSearchHandler,
      tableEntryManager,
      mooringArticleService,
      pubsub,
      confirmation,
      mooringService
    );

    pubsub.sub('list-entity:updated', (data) => {
      if (!data) return;
      if (data.name === 'mooring-article-component') {
        this.onSave({
          entries: this.entries,
          canNavigateOnSave: false,
        });
      }
    });

    pubsub.sub('tabs:change-guard', async () => {
      if (this.changeTracker.areEqual(this.entries)) {
        this.pubsub.publish('tabs:change-guard-ok', null);
        return;
      }

      const res = await this.confirmation.confirmSave();
      if (res == 'cancel') {
        return;
      } else if (res === 'discard-continue') {
        this.pubsub.publish('tabs:change-guard-ok', null);
      } else {
        this.onSave({ entries: this.entries, canNavigateOnSave: true });
      }
    });
  }

  async attached() {
    await super.attached();
  }

  protected sortTable() {
    console.log('sortTable');
  }

  protected onElementSelected(type: MooringComponentType, item: { id: number; title: string }, line: MooringAnchorLine) {
    if (type == 'buoy') {
      line.BuoyId = item.id;
      line.BuoyNo = item.title;
    }
  }

  protected onAddComponent() {
    this.entries.push({
      MoorlogNo: `X`,
    } as MooringAnchorLine);
  }

  @AsyncErrorHandler
  protected async onDelete(index: number) {
    const res = await this.confirmation.confirmDelete();
    if (!res) return;

    const entry = this.entries[index];
    if (!entry) return;

    if (entry.Id) {
      await this.mooringService.deleteAnchorLine(this.mooringId, entry.Id);
    }

    this.entries.splice(index, 1);
    this.toast.showDeleted();
  }

  protected save() {
    this.onSave({
      canNavigateOnSave: false,
      entries: this.entries,
    });
  }

  protected addEntryComment(component: MooringAnchorLine) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.AnchorLine,
    });
  }

  protected addComponentComment(component: MooringComponent) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.AnchorLineComponent,
    });
  }

  protected anchorLinenumberInputChange(index: number, name: (typeof tableColumns)[number]['field']) {
    const line = this.entries[index];

    if (name === 'LengthOfRope' || name === 'LengthOfBottomRope') {
      line.TotalLength = (+line.LengthOfBottomRope || 0) + (+line.LengthOfRope || 0);
    }
  }

  protected override postPasteAdjustment(index: number, name: (typeof tableColumns)[number]['field']) {
    this.anchorLinenumberInputChange(index, name);
  }

  protected unbind() {
    this.pubsub.unsub();
    super.unbind();
  }

  protected detached() {
    this.pubsub.unsub();
  }
}

const tableColumns: ListFieldTyped<MooringAnchorLine>[] = [
  {
    field: 'MoorlogNo',
    title: 'mooring.anchorLineNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'text',
    disableControl: true,
  },
  {
    field: 'AnalysisNo',
    title: 'mooring.analysisNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'text',
  },
  {
    field: 'BuoyNo',
    title: 'mooring.buoy',
    selected: true,
    visible: true,
    filterType: 'TEXT',
  },
  {
    field: 'DimForce',
    title: 'mooring.dimForce',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'IsAccident',
    title: 'mooring.isAccident',
    selected: true,
    visible: true,
    filterType: 'BOOL',
    transform: toChecked,
    inputType: 'bool',
  },
  {
    field: 'MinMblRopesStraps',
    title: 'mooring.minMblRope',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblChainShackleConnectionPlates',
    title: 'mooring.minMblChain',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblAnchoring',
    title: 'mooring.minMblAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'BottomAnchoringUplift',
    title: 'mooring.bottomAnchoringUplift',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'DepthAtAnchoringPoint',
    title: 'mooring.depthOfAnchoringPoint',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'Slope',
    title: 'mooring.incline',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'float',
  },
  {
    field: 'HoldingPowerAnchoring',
    title: 'mooring.holdingPowerAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'LengthOfBottomRope',
    title: 'mooring.lengthOfBottomChain',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'LengthOfRope',
    title: 'mooring.lengthOfRope',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'TotalLength',
    title: 'mooring.totalLengthByAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'StartPositionLat',
    title: 'mooring.anchorLineStartN',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'StartPositionLong',
    title: 'mooring.anchorLineStartE',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'StopPositionLat',
    title: 'mooring.anchorLineStopN',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'StopPositionLong',
    title: 'mooring.anchorLineStopE',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'actions',
    title: '',
    selected: true,
    visible: true,
    filterType: 'none',
  },
];
